export const MODAL_BACKGROUND = "#00000080",
  DISABLED_GRAY = "#6c6c6c",
  LIGHT_GRAY = "#B8BABE",
  LIGHT_GRAY_0 = "#EFEFEF",
  GRAY = "#87888A", //'#6c6c6c',
  MENU_GRAY = "#87888A",
  SHIMMER_GRAY = "#D1D1D1",
  BLACK = "#000000",
  WHITE = "#FFFFFF",
  RED = "#f44336",
  TEXT_TITLE = "#222222",
  TEXT_SUBTITLE = "#222222",
  // TEXT_DESCRIPTION = "#6c6c6c",
  TEXT_DESCRIPTION = "#222222",
  INPUT_ICON = BLACK,
  INPUT_TEXT = TEXT_TITLE,
  INPUT_ORANGE = "#FFAB40",
  GREEN = "#065F55",
  MID_GREEN = "#00897B",
  LIGHT_GREEN = "#00C854", //  '#00CD40',
  APP_BG_COLOR = "#E4DDD6", //"#D5DBDB", //'#f0f0ef',
  DASHBOARD_CARD_COLOR = WHITE,
  HEADER_COLOR = "#EDEDED",
  BAR_INACTIVE_COLOR =  '#87888ACC',//"#FFFFFF1A",
  TINT_GRAY =  "#0000001A",
  BAR_ACTIVE_COLOR = WHITE,
  SLATE_GRAY = '#778899';
